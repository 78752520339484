<template>
  <div class="receipt">
    <div v-if="loading">
      <MeetDetailItem
        :detail="detail"
        :conferenceRoomName="conferenceRoomName"
      ></MeetDetailItem>

      <div class="table-box">
        <table border="0" cellpadding="0" cellspacing="0" width="100%">
          <thead>
            <tr>
              <td>人员</td>
              <td>回执</td>
              <td>签到</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in detail.conferenceUserList" :key="index">
              <td>
                {{ item.realName }} <br />
                {{ item.userPhone }}
              </td>
              <td :class="item.receiptStatus == 1?'c-4E65DB':'c-E22A2A'">
                {{ item.receiptStatus == 0?'未回执':item.receiptStatus == 1?'参会':'不参会' ? "" : "" }} <br />
                {{ item.receiptTime }}
              </td>
              <td :class="item.signInStatus == 1?'c-4E65DB':'c-E22A2A'">
                {{ item.signInStatus == 0?'未签到':'已签到' }} <br />
                {{ item.signInTime }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="no-data">
      <img src="../../assets/no-data.png" alt="" />
    </div>

    <SucPopup ref="sPop"></SucPopup>
  </div>
</template>

<script>
import { button, Toast, NavBar, Cell, CellGroup } from "vant";
import Cookies from "js-cookie";
import SucPopup from "@/components/SucPopup";
import MeetDetailItem from "@/components/MeetDetailItem";
export default {
  name: "index",
  data() {
    return {
      detail: {},
      reservationId: this.$route.query.id,
      conferenceRoomName: this.$route.query.conferenceRoomName,
      showSign: true,
      loading: false,

      receiptStatusArray: [
          { value: "0", text: "未确认", title: "未确认" },
          { value: "1", text: "参会", title: "参会" },
          { value: "2", text: "不参会", title: "不参会" },
        ],
      signInStatusArray: [
          { value: "0", text: "未签到", title: "未签到" },
          { value: "1", text: "已签到", title: "已签到" },
        ],
    };
  },
  methods: {
    // 获取详情
    async scanCodeSignIn() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });
      let params = {
        reservationId: this.reservationId,
      };
      await this.$get(
        `/conference/reservation/queryReservationById`,
        params
      ).then((res) => {
        // alert(123)
        if (res.code == "0") {
          Toast.clear();
          this.loading = true;
          this.detail = res.result;
        }
        // else if(res.code == "501"){
        //     setTimeout(() => {
        //         this.$router.push({ path: "/binding" });
        //     }, 1000);
        //   }else if(res.code == "502"){
        //     Toast('您未被邀请参会');
        //     setTimeout(() => {
        //         Toast.clear()
        //         this.$router.push({ path: "/" });
        //     }, 1000);
        //   }else{
        //     Toast(res.message);
        //     setTimeout(() => {
        //         Toast.clear()
        //         this.$router.push({ path: "/" });
        //     }, 1000);
        //   }
      });
    },
  },
  mounted() {
    this.scanCodeSignIn();
  },
  components: {
    [Toast.name]: Toast,
    [button.name]: button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    SucPopup,
    MeetDetailItem,
  },
};
</script>

<style lang="scss" scoped>
.c-4E65DB{
    color: #4E65DB;
}
.c-E22A2A{
    color: #E22A2A;
}
.table-box {
  margin: 12px;
  background: #fff;
  padding: 12px;
  box-shadow: 0px 11px 16px 0px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  thead {
    background: #efefef;
    border-radius: 5px;

    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #4d4d4d;
    td {
      padding: 7px;
    }
  }
  tbody {
    font-size: 14px;
    tr {
      td {
        padding: 7px;
        border-bottom: 1px solid #dedede;
      }
      &:last-child {
        td {
          border-color: transparent;
        }
      }
    }
  }
}
.receipt {
  background: #f4f4f4;
  min-height: 100vh;
  overflow: hidden;
}

.btn-box {
  position: fixed;
  left: 0;
  bottom: 20px;
  width: 100%;
  .btn-inner {
    display: flex;
    margin: 12px;

    .btn-item {
      flex: 1;
      font-size: 16px;
      color: #ffffff;
      background: linear-gradient(0deg, #526eff 0%, #6f8eff 100%);
      border-radius: 25px;
      // margin: 34rpx 30rpx;
      line-height: 50px;
      text-align: center;
    }
    .btn-item1 {
      background: linear-gradient(0deg, #d58526 0%, #e5a04d 100%);
      margin-left: 11px;
    }
  }
}
</style>