<template>
  <div class="invited">
    <van-tabs class="stick" v-model="tabactive" @click="onClick">
      <van-tab title="全部"></van-tab>
      <van-tab title="待审核"></van-tab>
      <van-tab title="已取消"></van-tab>
      <van-tab title="已撤销"></van-tab>
      <van-tab title="已结束"></van-tab>
    </van-tabs>
    <div class="invited-list">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="invited-item" v-for="(item, index) in list" :key="index" @click="linkDetail(item)">
          <div class="invited-head">
            <div class="invited-name">{{ item.conferenceRoomName }}</div>
            <div class="invited-time">
              {{ item.conferenceDate }} {{ item.conferenceStartTime }}~{{
                item.conferenceEndTime
              }}
            </div>
            <div class="invited-btn" @click.stop="setReservationStatus(item)" v-if="item.status == 0">
              取消会议
            </div>
          </div>
          <CellItem :title="'会议主题'" :content="item.conferenceTheme"></CellItem>
          <!-- <CellItem
            :title="'会议地点'"
            :content="item.conferenceRoomPlace"
          ></CellItem> -->
          <CellItem :title="'会议状态'" :content="statusArr.filter((c) => {
            return c.value === item.status;
          })[0].text
            "></CellItem>
          <CellItem :title="'发起时间'" :content="item.insertTime"></CellItem>
        </div>
      </van-list>
    </div>

    <!-- <TabberItem :active="active"></TabberItem> -->
  </div>
</template>

<script>
import {
  Toast,
  List,
  Tab,
  Tabs,
  Dialog,
} from "vant";
import CellItem from "@/components/CellItem";
import TabberItem from "@/components/TabberItem";
import { initMixin } from "@/mixins/initMixin";
export default {
  name: "index",
  mixins: [initMixin],
  data() {
    return {
      loading: false,
      finished: false,
      current: 0,
      size: 10,
      code: this.$route.query.code,

      active: 1,
      tabactive: 0,

      list: [],
      status: "",

      statusArr: [
        { value: "0", text: "待审核", title: "待审核" },
        { value: "1", text: "已通过", title: "已通过" },
        { value: "2", text: "已取消", title: "已取消" },
        { value: "3", text: "已撤销", title: "已撤销" },
        { value: "4", text: "已拒绝", title: "已拒绝" },
        { value: "5", text: "使用中", title: "使用中" },
        { value: "6", text: "已结束", title: "已结束" },
      ],
    };
  },
  methods: {
    onClick(name, title) {
      if (title == "待审核") {
        this.status = "0";
      } else if (title == "已取消") {
        this.status = "2";
      } else if (title == "已撤销") {
        this.status = "3";
      } else if (title == "已结束") {
        this.status = "6";
      } else {
        this.status = "";
      }
      this.list = [];
      this.loading = false,
        this.finished = false,
        this.current = 0;
      this.getDate();
    },

    // 下拉加载列表
    onLoad() {
      setTimeout(() => {
        this.getDate();
      }, 1000);
    },

    // 跳转详情
    linkDetail(item) {
      return this.$router.push({
        path: "/myMeetingDetail",
        query: { id: item.id, conferenceRoomName: item.conferenceRoomName },
      });
    },

    // 改变状态
    async setReservationStatus(item) {
      Dialog.confirm({
        title: "",
        message: "确定取消会议",
      })
        .then(() => {
          // on confirm
          this.setReservationStatusChange(item);
        })
        .catch(() => {
          // on cancel
        });
    },
    async setReservationStatusChange(item) {
      let params = {
        reservationId: item.id,
        status: "2",
      };
      await this.$get(
        `/conference/reservation/setReservationStatus`,
        params
      ).then((res) => {
        if (res.code == "0") {
          this.list = [];
          this.current = 0;
          this.getDate();
        } else {
          Toast(res.message);
        }
      });
    },

    // 获取我的会议
    async getDate() {
      this.current++;
      let params = {
        status: this.status,
        page: {
          current: this.current,
          size: this.size,
        },
      };
      await this.$post(`/conference/reservation/queryReservation`, params).then(
        (res) => {
          if (res.code == "0") {
            if (this.list.length == 0) {
              this.list = res.result.records;
            } else {
              this.list = [...this.list, ...res.result.records];
            }
            // 加载状态结束
            this.loading = false;

            // 数据全部加载完成
            if (res.result.records.length < this.size) {
              this.finished = true;
            }
          }
        }
      );
    },
  },
  created() {
    this.initFn();
  },
  mounted() { },
  components: {
    [Toast.name]: Toast,
    [List.name]: List,
    [Dialog.name]: Dialog,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    CellItem,
    TabberItem,
  },
};
</script>

<style lang="scss" scoped>
.invited {
  background: #f4f4f4;
  min-height: 100vh;
}

.invited-list {
  overflow: hidden;
}

.invited-item {
  margin: 12px 12px 0;
  padding: 15px 19px;
  border-radius: 7px;
  background: #fff;

  .invited-head {
    position: relative;
    border-bottom: 1px solid #dedede;
    padding-bottom: 9px;

    .invited-name {
      font-size: 18px;
      font-weight: bold;
      color: #4d4d4d;
    }

    .invited-time {
      margin-top: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #7e7e7e;
    }

    .invited-btn {
      position: absolute;
      right: 0;
      top: 3px;
      font-size: 14px;
      font-weight: 400;
      color: #fefefe;
      background: #e59c45;
      border-radius: 14px;
      // padding: 6px 13px;
      width: 70px;
      height: 28px;
      line-height: 28px;
      text-align: center;

      &.btn-sign {
        background: #526eff;
      }
    }
  }
}

.stick {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  z-index: 111;
}

::v-deep .van-tab--active {
  color: #171717;
  font-weight: bold;
}

::v-deep .van-tabs__line {
  background: #526eff;
}
</style>