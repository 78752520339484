<template>
    <div class="receipt-list">
        <div class="receipt-item">
            <div class="receipt-head">{{detail.conferenceTheme}}</div>

            <CellItemOther :title="'会议时间'" :content="detail.conferenceDate+' '+detail.conferenceStartTime+'~'+detail.conferenceEndTime"></CellItemOther>
            <CellItemOther :title="'会议室'" :content="conferenceRoomName"></CellItemOther>
            <!-- <CellItemOther :title="'会议地点'" :content="detail.conferenceRoomPlace"></CellItemOther> -->
            <CellItemOther :title="'是否需要会务'" class="otherwidth" :content="detail.isMeetingAffairs == '1'?'是':'否'"></CellItemOther>
            <CellItemOther :title="'承办部门'" :content="detail.undertakeDepartName"></CellItemOther>
            <CellItemOther :title="'是否需要座牌'" class="otherwidth" :content="detail.isSeatPlate == '1'?'是':'否'"></CellItemOther>
            <CellItemOther :title="'座牌信息'" :content="detail.seatPlateInfo"></CellItemOther>
            <CellItemOther :title="'所需物料'" :content="detail.material"></CellItemOther>
            <CellItemOther :title="'发起时间'" :content="detail.insertTime"></CellItemOther>
            <!-- <CellItemOther :title="'回执状态'" :content="detail.receiptStatus == 0?'未回执':detail.receiptStatus == 1?'参会':'不参会'"></CellItemOther>
            <CellItemOther :title="'签到状态'" :content="detail.signInStatus == 0?'未签到':'已签到'"></CellItemOther> -->
        </div>
    </div>
</template>

<script>
import CellItemOther from '@/components/CellItemOther'
export default {
  name: "CellItem",
  props: {
    title: String,
    conferenceRoomName: String,
    content:String,
    detail:Object
  },
  components: {
    CellItemOther
  },
};
</script>

<style lang="scss" scoped>
    ::v-deep .otherwidth .cell-left{width: 100px;}
    .receipt-list{
        overflow: hidden;
        .receipt-item{
          margin: 12px 12px 0;
          padding: 20px 19px;
          border-radius: 7px;
          background: #fff;
          .receipt-head{
              position: relative;
              border-bottom: 1px solid #DEDEDE;
              padding-bottom: 20px;

              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #4E65DB;
              text-align: center;

          }
      }
    }
</style>